import './App.css'

import React from 'react'

// import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg'
import { ReactComponent as IconGithub } from './assets/icons/github.svg'

// import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg'

class App extends React.Component {
  render = () => {
    return (
      <div className='card'>
        <div className='header'>
          <div className='logo'>{/* <a href='.'>LOGO</a> */}</div>
          <div className='social'>
            {/* <a
              href='https://facebook.com'
              title='Facebook'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconFacebook className='icon' />
            </a> */}
            {/* <a
              href='https://twitter.com'
              title='Twitter'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconTwitter className='icon' />
            </a> */}
            <a
              href='https://github.com/desmondpnw3dev'
              title='GitHub'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IconGithub className='icon' />
            </a>
          </div>
        </div>
        <div className='content'>
          <div className='title-holder'>
            <h1>Want to hire Desmond Jones?</h1>
            <p>
              Sorry, we're currently undergoing scheduled maintenance. Please
              check back soon.
            </p>
          </div>
          {/* <a href='mailto:user@example.com'>
            <div className='cta'>Send us an email</div>
          </a> */}
        </div>
        <div className='footer'></div>
      </div>
    )
  }
}

export default App
